import './bootstrap';
import '../css/app.scss';


import {openobserveRum} from '@openobserve/browser-rum';
import {openobserveLogs} from '@openobserve/browser-logs';


const options = {
    clientToken: import.meta.env.VITE_OPENOBSERVE_KEY,
    applicationId: import.meta.env.VITE_OPENOBSERVE_APPLICATION_ID,
    site: import.meta.env.VITE_OPENOBSERVE_SITE,
    service: import.meta.env.VITE_OPENOBSERVE_SERVICE,
    env: 'production',
    version: '0.0.1',
    organizationIdentifier: import.meta.env.VITE_OPENOBSERVE_ORG,
    insecureHTTP: false,
    apiVersion: 'v1',
};


openobserveRum.init({
    applicationId: options.applicationId, // required, any string identifying your application
    clientToken: options.clientToken,
    site: options.site,
    organizationIdentifier: options.organizationIdentifier,
    service: options.service,
    env: options.env,
    version: options.version,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    apiVersion: options.apiVersion,
    insecureHTTP: options.insecureHTTP,
    defaultPrivacyLevel: 'allow' // 'allow' or 'mask-user-input' or 'mask'. Use one of the 3 values.
});

openobserveLogs.init({
    clientToken: options.clientToken,
    site: options.site,
    organizationIdentifier: options.organizationIdentifier,
    service: options.service,
    env: options.env,
    version: options.version,
    forwardErrorsToLogs: true,
    insecureHTTP: options.insecureHTTP,
    apiVersion: options.apiVersion,
});

import translateHelper from './base';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {createI18n} from 'vue-i18n';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';
import {createVfm} from 'vue-final-modal'
import {LoadingPlugin} from 'vue-loading-overlay';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/styles";

import emitter from "./core/event";


createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    async setup({el, App, props, plugin}) {
        const i18n = createI18n({
            locale: props.initialPage.props.locale ? props.initialPage.props.locale as string : '', // user locale by props
            fallbackLocale: "en", // set fallback locale
            legacy: false,
            fallbackWarn: false,
            missingWarn: false,
            messages: {
                'en': await (await fetch(`/locale/en.json`)).json(),
                'da': await (await fetch(`/locale/da.json`)).json(),
            }
        });

        window.i18n = i18n

        const vfm = createVfm()

        const app = createApp({render: () => h(App, props)})

        // const hasListeners = <T extends keyof Events>(eventName: T): boolean => {
        //     return !!(emitter.all[eventName] && emitter.all[eventName].length);
        // };

        const hasListeners = (eventName: string): boolean => {
            return !!(emitter.all[eventName] && emitter.all[eventName].length);
        };

        window.addEventListener('popstate', (event) => {
            if (hasListeners('popstate')) {
                console.log('popstate')
                event.stopImmediatePropagation();
            }
            emitter.emit('popstate', event)
        });


        return app
            .use(plugin)
            .use(LoadingPlugin)
            .use(vfm)
            .use(i18n)
            .use(SnackbarService)
            // .use(PrimeVue, {
            //     theme: {
            //         preset: Aura
            //     }
            // })
            .use(ZiggyVue)
            .mixin(translateHelper)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
        showSpinner: false,
    },
});
