function __translate(
    key: string,
    replace: Record<string, string> = {},
    variants: Record<string, any> | any | null = null
): any | string {
    const sub = key.split('.', 2);
    let translation: Record<string, any> | string | null;

    if (!(variants && variants[sub[0]])) {
        return key;
    }

    translation = variants[sub[0]];
    if (!translation) {
        return key;
    }

    if (sub[1]) {
        return __translate(sub[1], replace, translation);
    }

    Object.keys(replace).forEach((replaceKey) => {
        translation = translation!.replace(`:${replaceKey}`, replace[replaceKey]);
    });

    return translation;
}

export default {
    methods: {
        __(key: string, replace: Record<string, string> = {}, variants: Record<string, any> | any | null = null): string {
            variants = variants ? variants : (this as any).$page.props.language;
            return __translate(key, replace, variants);
        },
    },
};
